import React, { useState, useEffect } from 'react';
import './HomePage.css';
import { Helmet } from 'react-helmet';
import { analytics } from './firebase';
import { logEvent } from 'firebase/analytics';

const HomePage = () => {
  const [showImage, setShowImage] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  //const centeredImageSrc = isMobile ? '/mobileAnn.png' : '/MsAnn.png';
  const centeredImageSrc = isMobile ? '/MsAnn.png' : '/MsAnn.png';

  const setDynamicHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    setDynamicHeight();
    window.addEventListener('resize', setDynamicHeight);
    return () => window.removeEventListener('resize', setDynamicHeight);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    handleResize(); // Check on mount
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    logEvent(analytics, 'homepage_visited');

    const timer = setTimeout(() => {
      setShowImage(true);
      logEvent(analytics, 'image_displayed', { imageSrc: centeredImageSrc });
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const handleIconClick = (platform, url) => {
    logEvent(analytics, 'social_media_icon_clicked_'+platform, { platform });
    window.open(url, '_blank');
  };

  return (
    <div className="home-page">
      <Helmet>
        <title>BLYS</title>
        <meta name="description" content=" Indulge in a moment of pure bliss with BLYS's range of delicious plantbased, refined sugar-free cookies, cakes, and donuts. Discover guilt-free treats today!" />
        <meta name="keywords" content="BLYS.eco, daily bliss, delicious treats, social media, Facebook, Instagram, YouTube, TikTok" />
        <meta property="og:title" content="BLYS: Vegan & Gluten-Free Bliss" />
        <meta property="og:description" content="Welcome to BLYS.eco, where you can create daily bliss with our delicious treats and inspiring content. Join us on social media for more updates!" />
        <meta property="og:image" content="/Logo.png" />
        <meta property="og:url" content="https://www.blys.eco" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Home Page - Create Daily Bliss" />
        <meta name="twitter:description" content="Welcome to BLYS.eco, where you can create daily bliss with our delicious treats and inspiring content. Join us on social media for more updates!" />
        <meta name="twitter:image" content="/Logo.png" />
      </Helmet>
      <img src="/Logo.png" alt="Logo" className="logo" />
      <div className="subtitle">create daily bliss</div>
      <div className="background-image"></div>
      {showImage && <img src={centeredImageSrc} alt="Centered" className="centered-image" />}
      <div className="social-media-icons">
        <img src="/facebook.png" alt="Facebook" onClick={() => handleIconClick('facebook', 'https://www.facebook.com/profile.php?id=61561396684284&mibextid=LQQJ4d')} />
        <img src="/Ig.png" alt="Instagram" onClick={() => handleIconClick('instagram', 'https://www.instagram.com/blys.eco/')} />
        <img src="/Youtube.png" alt="YouTube" onClick={() => handleIconClick('youtube', 'https://youtube.com/@blys_eco?si=vyP5gvueAvbavMVM')} />
        <img src="/Tiktok.png" alt="TikTok" onClick={() => handleIconClick('tiktok', 'https://www.tiktok.com/@blys.eco?_t=8o5jNj7AHnV&_r=1')} />
      </div>
      <footer className="footer">
        <div className="footer-left">Good things come to those who wait … (and crave delicious treats!)</div>
        <div className="footer-right">© 2024 BLYS.eco. All rights Reserved.</div>
      </footer>
    </div>
  );
};

export default HomePage;
