// firebase.js
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyDnsKeUujdyXY6E8MS1dn2zGHyd2_bjkVI",
    authDomain: "blys-878de.firebaseapp.com",
    projectId: "blys-878de",
    storageBucket: "blys-878de.appspot.com",
    messagingSenderId: "725789675740",
    appId: "1:725789675740:web:3789c3c603763063f72419",
    measurementId: "G-9G6V3Q2CJW"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics };